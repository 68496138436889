import React, { useState } from "react";
import moment from "moment";
import {
  Col,
  Row,
  List,
  Button,
  Menu,
  Dropdown,
  Skeleton,
  Card,
  Divider, Select, PageHeader,
} from "antd";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { getCalendar, removeFromCalendar } from "../api/User";
import { CompetitionTypeTag } from "artemis-shared/tag/CompetitionTypeTag";
import { MenuOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import "./UserCalendar.less";
import Swal from "sweetalert2";
import {useHistory} from "react-router";

export const UserCalendar = () => {
  const cache = useQueryCache();

  const { data: calendar, isFetching } = useQuery("calendar", getCalendar);

  const [selectedYear, setSelectedYear] = useState(2023);

  let history = useHistory();

  const [removeMutate] = useMutation(removeFromCalendar, {
    onSuccess: () => {
      cache.invalidateQueries(["calendar"]);
      Swal.fire({
        title: "Eltávolítva a naptáradból!",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  });

  const [showAll, setShowAll] = useState(false);

  const getSundaysOfYear = (year) => {
    let startOfYear = moment(`${year - 1}-12-31`);
    let endOfYear = moment(`${year}-12-31`);

    let result = [];
    let current = startOfYear.clone();

    while (current.add(1, "day").isSameOrBefore(endOfYear)) {
      if (current.isoWeekday() === 7) {
        result.push(current.clone());
      }
    }

    return result;
  };

  const generateCalendar = (year) => {
    let sundays = getSundaysOfYear(year);

    return sundays.map((sunday) => {
      let monday = sunday.clone().isoWeekday(1);

      return {
        monday: monday,
        sunday: sunday,
        competitions: calendar.filter((competition) =>
          moment(competition.startDate).isBetween(monday, sunday, "day", "[]")
        ),
      };
    });
  };

  const generateMonths = (year) => {
    let calendar = generateCalendar(year);

    let months = [];

    for (let i = 0; i < 12; i++) {
      months[i] = [];
    }

    calendar.forEach((calendarItem) => {
      for (let i = 0; i < 12; i++) {
        if (
          calendarItem.sunday.isBetween(
            moment().year(year).month(i).startOf("month"),
            moment().year(year).month(i).endOf("month")
          )
        ) {
          months[i].push(calendarItem);
        }
      }
    });

    return months;
  };

  const menu = (competition) => {
    return (
      <Menu>
        <Menu.Item>
          <NavLink to={`/competition/${competition.id}/${competition.slug}`}>
            Részletek
          </NavLink>
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${competition.lat},${competition.lng}`
            )
          }
        >
          Megtekintés Google Maps-en
        </Menu.Item>
        <Menu.Item onClick={() => removeMutate(competition.id)}>
          Eltávolítás
        </Menu.Item>
      </Menu>
    );
  };

  const weekElement = (week) => {
    return (
      <List
        dataSource={week.competitions}
        loading={isFetching}
        renderItem={(competition) => (
          <List.Item
            key={competition.id}
            actions={[
              <Dropdown
                key={"menu"}
                overlay={menu(competition)}
                placement="bottomRight"
                trigger={"click"}
              >
                <Button>
                  <MenuOutlined />
                </Button>
              </Dropdown>,
            ]}
          >
            <Skeleton title={false} loading={isFetching} active>
              <List.Item.Meta
                title={
                  <>
                    <span>{competition.name}</span>
                  </>
                }
                description={
                  <div>
                    <CompetitionTypeTag type={competition.type} />
                    {competition.location} - {competition.startDate}
                  </div>
                }
              />
            </Skeleton>
          </List.Item>
        )}
      />
    );
  };

  console.log("months", calendar && generateMonths(selectedYear));

  return (
    <div className={"user-calendar"}>
      <PageHeader
          className="site-page-header"
          onBack={() => history.push("/")}
          title={"Naptáram"}
          extra={
            <Select
                style={{ width: 90 }}
                value={selectedYear}
                onChange={setSelectedYear}
            >
              <Select.Option key={"2024"} value={2024}>
                2024
              </Select.Option>
              <Select.Option key={"2023"} value={2023}>
                2023
              </Select.Option>
              <Select.Option key={"2022"} value={2022}>
                2022
              </Select.Option>
              <Select.Option key={"2021"} value={2021}>
                2021
              </Select.Option>
            </Select>
          }
      />
      <Row gutter={[16, 16]}>
        <Col xs={0} md={24}>
          <Row gutter={[16, 16]}>
            {calendar &&
              generateMonths(selectedYear).map((month, index) => {
                return (
                  <Col
                    key={moment().month(index).format("MMMM")}
                    md={12}
                    xl={8}
                  >
                    <Card title={moment().month(index).format("MMMM")}>
                      {month.map((week) =>
                        week.competitions.length === 0 ? (
                          <div>
                            <Divider orientation="left">
                              {week.monday.format("MM.DD.")} -{" "}
                              {week.sunday.format("MM.DD.")}
                            </Divider>
                            Nincs versenyed
                          </div>
                        ) : (
                          <>
                            <div>
                              <Divider orientation="left">
                                {week.monday.format("MM.DD.")} -{" "}
                                {week.sunday.format("MM.DD.")}
                              </Divider>
                              {weekElement(week)}
                            </div>
                          </>
                        )
                      )}
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Col>
        <Col xs={24} md={0}>
          {showAll && (
            <Button type="link" block onClick={() => setShowAll(false)}>
              Korábbi hetek elrejtése.
            </Button>
          )}
          {!showAll && (
            <Button type="link" block onClick={() => setShowAll(true)}>
              Korábbi hetek megjelenítése.
            </Button>
          )}
          {calendar && (
            <List
              itemLayout="vertical"
              dataSource={generateCalendar(selectedYear)}
              renderItem={(item, index) => {
                if (showAll || moment().isSameOrBefore(item.sunday)) {
                  return (
                    <Card
                      title={`${index + 1}. hét - ${item.monday.format(
                        "MM.DD"
                      )} - ${item.sunday.format("MM.DD")}`}
                    >
                      <List.Item>
                        {item.competitions.length === 0 && (
                          <div>Nincs versenyed ezen a héten</div>
                        )}

                        {item.competitions.length > 0 && weekElement(item)}
                      </List.Item>
                    </Card>
                  );
                } else {
                  return null;
                }
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
